import React from 'react';

const Profile = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="self-center w-full max-w-[1170px] mt-20 px-5 max-md:max-w-full max-md:mt-10">
        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
          <div className="flex flex-col items-stretch w-[42%] max-md:w-full max-md:ml-0">
            <div className="items-stretch flex grow flex-col max-md:max-w-full max-md:mt-10">
              <div className="text-slate-900 text-4xl font-bold leading-[50px] max-md:max-w-full">We're on a Mission to turn Your Dream's into Reality.</div>
              <img loading="lazy" srcSet="/img/Boney.jpg" className="aspect-[0.84] object-contain object-center w-[343px] h- overflow-hidden max-w-full mt-4 self-start" alt="Mission" />
            </div>
          </div>
          <div className="flex flex-col items-stretch w-[58%] ml-5 mt-6 max-md:w-full max-md:ml-0">
            <div className="text-slate-900 text-base leading-7 max-md:max-w-full max-md:mt-10">
              Experience the essence of Dream Vision Homes, where we transform aspirations into tangible realities. With a steadfast 20-year presence in real estate, we've established a legacy of excellence and contented clients. At Dream Vision Homes, we understand the weight of real estate decisions, handling each transaction with meticulous care, personalized attention, and a commitment to exceeding expectations.
              <br /> <br />
              Our adept real estate agents boast extensive knowledge of local market trends, property values, and legal intricacies, ensuring your decisions align seamlessly with your unique goals and budget. Beyond the closing table, we prioritize nurturing enduring relationships. Whether you're a first-time buyer, seasoned investor, or selling your property, rely on Dream Vision Homes for unparalleled support and expertise.
              <br /> <br />
              Contact us today to embark on a transformative real estate journey and redefine what's possible with Dream Vision Homes. Let us guide you through every step with unwavering dedication, ensuring your dreams materialize into cherished realities.
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
